<template>
    <div class="section-wrapper">
        <BreadCumb v-if="$route.name !== 'tea_garden_service.dashboard'"/>
        <div class="form-wrapper btb-return-db">
            <b-card :title="$t('teaGardenDashboard.return_form') + ' ' + ($i18n.locale === 'en' ? 'Dashboard' : 'ড্যাশবোর্ড')">
                <b-row>
                    <b-col cols="12">
                        <b-card-text>
                            <b-row>
                                <b-col sm="6">
                                    <b-form-group label-for="year">
                                        <template v-slot:label>
                                            {{$t('globalTrans.year')}}
                                        </template>
                                        <b-form-select plain v-model="search.year" :options="yearList" @change="searchData" id="year">
                                            <template v-slot:first>
                                                <b-form-select-option disabled :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="6">
                                    <b-form-group label-for="month">
                                        <template v-slot:label>
                                            {{$t('globalTrans.month')}}
                                        </template>
                                        <b-form-select plain v-model="search.month" :options="monthList" @change="searchData" id="month">
                                            <template v-slot:first>
                                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-col>
                </b-row>
            </b-card>
            <b-card class="mt-3 tea-garden-header">
                <b-row>
                    <b-col sm="6">
                        <router-link to="/tea-garden-service/configuration/tea-garden-profile">
                            <b-card class="info-box text-dark">
                                <p>{{ $t('teaGardenDashboard.total_garden') }}</p>
                                <h2>{{ $n(masterTeaGardenGenInfoList.length) }}</h2>
                            </b-card>
                        </router-link>
                    </b-col>
                    <b-col sm="6">
                        <router-link to="/tea-garden-service/configuration/bought-leaf-factory">
                            <b-card class="info-box text-dark">
                                <p>{{ $t('teaGardenDashboard.total_factory') }}</p>
                                <h2>{{ $n(masterBoughtLeafFactoryList.length) }}</h2>
                            </b-card>
                        </router-link>
                    </b-col>
                </b-row>
            </b-card>
            <div class="tea-return-wrapper">
                <b-overlay :show="unitLoad">
                <b-row>
                    <b-col sm="4">
                        <b-card>
                            <b-card-body>
                                <div class="p-1">
                                    <router-link to="/tea-garden-service/garden/return-one"><span class="return-badge">{{ $t('teaGardenDashboard.return_form_one') }}</span></router-link>
                                    <b-row no-gutters>
                                        <b-col sm="4">
                                            <img src="../../../../../assets/images/tea-garden/return-1.jpeg" alt="">
                                        </b-col>
                                        <b-col sm="8">
                                            <div class="py-2 px-2">
                                                <div>
                                                    <p>{{ $t('teaGardenDashboard.garden_submitted') }}</p>
                                                    <h4 class="">{{ dashData?.returnOne?.garden ? $n(dashData?.returnOne?.garden) : $n(0) }}</h4>
                                                </div>
                                                <div class="border-top">
                                                    <p>{{ $t('teaGardenDashboard.factory_submitted') }}</p>
                                                    <h4 class="">{{ dashData?.returnOne?.factory ? $n(dashData?.returnOne?.factory) : $n(0) }}</h4>
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card>
                            <b-card-body>
                                <div class="p-1">
                                    <router-link to="/tea-garden-service/garden/return-form-2"><span class="return-badge">{{ $t('teaGardenDashboard.return_form_two') }}</span></router-link>
                                    <b-row no-gutters>
                                        <b-col sm="4">
                                            <img src="../../../../../assets/images/tea-garden/return-2.jpeg" alt="">
                                        </b-col>
                                        <b-col sm="8">
                                            <div class="py-2 px-2">
                                                <div>
                                                    <p>{{ $t('teaGardenDashboard.garden_submitted') }}</p>
                                                    <h4 class="">{{ dashData?.returnTwo?.garden ? $n(dashData?.returnTwo?.garden) : $n(0) }}</h4>
                                                </div>
                                                <div class="border-top">
                                                    <p>{{ $t('teaGardenDashboard.factory_submitted') }}</p>
                                                    <h4 class="">{{ dashData?.returnTwo?.factory ? $n(dashData?.returnTwo?.factory) : $n(0) }}</h4>
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card>
                            <b-card-body>
                                <div class="p-1">
                                    <router-link to="/tea-garden-service/garden/return-three"><span class="return-badge">{{ $t('teaGardenDashboard.return_form_three') }}</span></router-link>
                                    <b-row no-gutters>
                                        <b-col sm="4">
                                            <img src="../../../../../assets/images/tea-garden/return-3.jpeg" alt="">
                                        </b-col>
                                        <b-col sm="8">
                                            <div class="py-2 px-2">
                                                <div>
                                                    <p>{{ $t('teaGardenDashboard.garden_submitted') }}</p>
                                                    <h4 class="">{{ dashData?.returnThree?.garden ? $n(dashData?.returnThree?.garden) : $n(0) }}</h4>
                                                </div>
                                                <div class="border-top">
                                                    <p>{{ $t('teaGardenDashboard.factory_submitted') }}</p>
                                                    <h4 class="">{{ dashData?.returnThree?.factory ? $n(dashData?.returnThree?.factory) : $n(0) }}</h4>
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
                </b-overlay>
            </div>
            <!-- <b-card>
                <div>
                    <img class="img-fluid" src="../../../../../assets/images/chart.png" alt="">
                </div>
            </b-card> -->
        </div>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import BreadCumb from '@/components/BreadCumb.vue'
import { helpers } from '@/utils/helper-functions'
export default {
  name: 'ReturnDashboard',
  props: [],
  components: {
    BreadCumb
  },
  data () {
    return {
        unitLoad: false,
        search: {
            year: 0,
            month: 0
        },
        dashData: {
            returnOne: '',
            returnTwo: '',
            returnThree: ''
        }
    }
  },
  computed: {
    yearList () {
      return helpers.getYearList()
    },
    monthList: function () {
      return this.$store.state.commonObj.monthList.map(el => {
        return Object.assign(el, {}, { text: this.$i18n.locale === 'en' ? el.text_en : el.text_bn })
      })
    },
    masterTeaGardenGenInfoList () {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(el => el.status === 1)
    },
    masterBoughtLeafFactoryList () {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(el => el.status === 1)
    }
  },
  created () {
    this.search.year = new Date().getFullYear()
    this.loadData()
  },
  methods: {
    searchData () {
        this.loadData()
    },
    async loadData () {
        this.unitLoad = true
        const result = await RestApi.getData(teaGardenServiceBaseUrl, 'dashboard/return-form', this.search)
        if (result.success) {
            this.unitLoad = false
            this.dashData = result.data
            this.unitLoad = false
        } else {
            this.unitLoad = false
        }
    }
  }
}
</script>
<style>
.btb-return-db .tea-garden-header{
    padding: 2rem 2rem 6rem 2rem;
    background-image: linear-gradient(to right, #11998ee5, #38ef7ee9), url(../../../../../assets/images/tea-gardens-two.jpg);
    background-position: center;
    background-size: cover;
    border: none;
    border-radius: 5px;
}
.btb-return-db .tea-garden-header .info-box{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1rem;
    margin: 0;
    backdrop-filter: blur(4px) saturate(152%);
    -webkit-backdrop-filter: blur(4px) saturate(152%);
    background-color: rgba(255, 255, 255, 0.53);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.btb-return-db .tea-return-wrapper{
    padding: 0 3rem;
    margin-top: -5rem;
}
.btb-return-db .tea-return-wrapper .card{
    position: relative;
}
.btb-return-db .tea-return-wrapper .card img{
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}
.btb-return-db .tea-return-wrapper .card .return-badge{
    position: absolute;
    bottom: -17px;
    right: 15px;
    color: #fff;
    /* padding: 0 5px; */
    padding: 6px;
    z-index: 1;
    border-radius: 2px;
    background: #06b98f;
}
</style>
